<template>
  <screen-table
    class="indexlayout-main-conent"
    row-key="id"
    :data="list"
    :total="total"
    :pageChange="pageChange"
  >
    <template #header>
      <League-select @change="changeSelect" />
    </template>
  </screen-table>
</template>

<script lang="ts">
import {Options, Vue} from 'vue-class-component';
import screenTable from './ScreenTable/index.vue';
import Service from '@/services/server/web';
import {IMatchResult, IMatchList} from '@/model';
import {LeagueSelect} from '@/common/web';
@Options({
  components: {
    screenTable,
    LeagueSelect
  }
})
export default class Login extends Vue {
  //列表
  list: Array<IMatchList> = [];
  //总条数
  total = 0;
  //当前页数
  page = 1;
  //联级选择获取数据
  selectValue = '';

  created() {
    this.getList();
  }

  /**
   * 获取列表数据
   */
  async getList() {
    const res = await Service.Common.getMatchList<IMatchResult>({
      page: this.page,
      size: 10,
      // eslint-disable-next-line @typescript-eslint/camelcase
      league_code: this.selectValue
    });
    if (!(res instanceof Error)) {
      this.list = JSON.parse(JSON.stringify(res.data));
      this.total = res.total;
    }
  }

  /**
   * 翻页
   */
  pageChange(e: number) {
    this.page = e;
    this.getList();
  }

  /**
   *
   */
  changeSelect(e: string) {
    this.selectValue = e;
    this.getList();
  }
}
</script>
<style lang="scss" scoped>
@import '../../../common/styles/element.scss';
</style>
