<template>
  <div class="indexlayout-main-conent" v-loading="loading">
    <el-card shadow="never" class="cus-card" style="margin-bottom: 20px">
      <template #header>{{ $t('match__from') }}</template>

      <el-row :gutter="24">
        <el-col :span="24">
          <div class="disFlex local">
            <span> {{ $t('match__home') }}</span>
            <span class="disFlex">
              <img
                :src="`http://${detail.home_domain}${detail.home_icon}`"
                alt=""
                class="team-icon"
              />
              {{ detail.home }}</span
            >
            <span class="score" v-if="detail.home_score">
              <span> {{ $t('match__all') }}:{{ JSON.parse(detail.home_score)[0] }} </span>
              <span> {{ $t('match__half') }}:{{ JSON.parse(detail.home_score)[1] }}</span>
              <span>{{ $t('match__score') }}:{{ JSON.parse(detail.home_score)[2] || '-' }}</span>
            </span>
          </div>
        </el-col>
        <el-col :span="24">
          <div class="disFlex local">
            <span>{{ $t('match__away') }}</span>
            <span class="disFlex">
              <img
                :src="`http://${detail.away_domain}${detail.away_icon}`"
                alt=""
                class="team-icon"
              />
              {{ detail.away }}</span
            >
            <span class="score" v-if="detail.away_score">
              <span>{{ $t('match__all') }}:{{ JSON.parse(detail.away_score)[0] }}</span>
              <span> {{ $t('match__half') }}:{{ JSON.parse(detail.away_score)[1] }}</span>
              <span> {{ $t('match__score') }}:{{ JSON.parse(detail.away_score)[2] || '-' }}</span>
            </span>
          </div>
        </el-col>
      </el-row>
    </el-card>

    <el-card shadow="never" class="cus-card" style="margin-bottom: 20px">
      <el-table :data="detail.stats" style="width: 100%">
        <el-table-column :label="$t('match__statistics')">
          <template #default="scope">
            {{ $t(`match__stats${scope.row.type}`) }}
          </template>
        </el-table-column>
        <el-table-column prop="home" :label="$t('match__home')"> </el-table-column>
        <el-table-column prop="away" :label="$t('match__away')"> </el-table-column>
      </el-table>
    </el-card>

    <el-card shadow="never" class="cus-card" style="margin-bottom: 20px">
      <el-table :data="detail.event" style="width: 100%">
        <el-table-column prop="data" :label="$t('match__process')"> </el-table-column>
      </el-table>
    </el-card>
  </div>
</template>
<script>
import {MatchStatus as M} from '@/model';
import {Server} from '@/services';
export default {
  props: {
    code: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      detail: M.matchInitDetail(),
      stats: M.matchStats()
    };
  },
  created() {
    this.getDetail();
    console.log(this.code);
  },
  methods: {
    async getDetail() {
      const res = await Server.Web.Common.getMatchDetail({
        code: this.code || 'leisu_3464972'
      });
      if (!(res instanceof Error)) {
        this.detail = res;
      }
    }
  }
};
</script>
<style lang="scss" scoped>
@import '../../../../common/styles/element.scss';
.team-icon {
  width: 24px;
  height: 24px;
  margin: 0 10px 0 20px;
}
.local {
  font-size: 14px;
  margin: 10px 0;
  .score {
    font-size: 12px;
    margin-left: 30px;
    flex-grow: 1;
    text-align: right;
    span {
      width: 60px;
      display: inline-block;
      text-align: left;
    }
  }
}
.indexlayout-main-conent {
  margin: 20px 0;
}
</style>
