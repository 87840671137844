<template>
  <div class="main-conent-screen" v-if="!isDetail">
    <div v-if="$slots.header" class="screen-header">
      <slot name="header"></slot>
    </div>
    <div v-else class="screen-padding" />
    <div class="screen-conent">
      <el-table :data="data" style="width: 100%" @cell-click="tabHanlde">
        <el-table-column prop="league_name" :label="$t('match__league')" width="200">
        </el-table-column>
        <el-table-column :label="$t('match__from')">
          <template #default="scope">
            <!-- scope.row -->
            <div class="disFlexs flexStart">
              <span> {{ scope.row.home_name }}</span>
              <span> {{ scope.row.away_name }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column :label="$t('match__score')">
          <template #default="scope">
            <div class="disFlex">
              <div class="disFlexs flexStart color">
                <span>{{ $t('match__all') }}</span>
                <span> {{ JSON.parse(scope.row.home)[0] }}</span>
                <span> {{ JSON.parse(scope.row.away)[0] }}</span>
              </div>
              <div class="disFlexs flexStart color">
                <span>{{ $t('match__half') }}</span>
                <span> {{ JSON.parse(scope.row.home)[1] }}</span>
                <span> {{ JSON.parse(scope.row.away)[1] }}</span>
              </div>
              <div class="disFlexs flexStart color">
                <span>{{ $t('match__sort') }}</span>
                <span> {{ JSON.parse(scope.row.home)[2] || '-' }}</span>
                <span> {{ JSON.parse(scope.row.away)[2] || '-' }}</span>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column :label="$t('match__status')" width="100">
          <template #default="scope">
            <!-- scope.row -->
            {{ cm.matchStatus($t)[scope.row.status] }}
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="screen-footer page">
      <el-pagination layout="prev, pager, next" :total="total" @current-change="pageChange" />
    </div>
  </div>
  <Content-detail v-else v-model="isDetail">
    <Match-detail :code="code" />
  </Content-detail>
</template>
<script lang="ts">
import {Options, Vue} from 'vue-class-component';
import {MatchStatus as M, MatchStatus} from '@/model';
import {ContentDetail} from '@/common/web';
import MatchDetail from './matchDetail.vue';

@Options({
  props: {
    data: {
      type: Array
    },
    pageChange: Function,
    total: {
      type: Number
    }
  },
  components: {
    ContentDetail,
    MatchDetail
  }
})
export default class Login extends Vue {
  cm = M;
  isDetail = false;
  code = '';
  async tabHanlde(e: {code: string}) {
    this.code = e.code;
    this.isDetail = true;
  }
}
</script>
<style lang="scss" scoped>
@import '../../../../common/styles/element.scss';
$y: #f9cc9d;
$g: #67c23a;
.main-conent-screen {
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  background-color: #fff;
  .screen-header {
    padding: 20px;
    min-height: 33px;
  }
  .screen-footer {
    padding: 20px;
    min-height: 32px;
    text-align: right;
  }
  .screen-conent {
    flex: 1;
    padding: 0 20px;
    overflow: hidden;
  }
  .screen-padding {
    padding-top: 20px;
  }
}
.page {
  width: unset;
}
.flexStart {
  align-items: flex-start;
  white-space: nowrap;
  &.color {
    color: $y;
    padding: 0 2px;
  }
  &.color:nth-child(2n) {
    color: $g;
  }
}
</style>
