
import {Options, Vue} from 'vue-class-component';
import {MatchStatus as M, MatchStatus} from '@/model';
import {ContentDetail} from '@/common/web';
import MatchDetail from './matchDetail.vue';

@Options({
  props: {
    data: {
      type: Array
    },
    pageChange: Function,
    total: {
      type: Number
    }
  },
  components: {
    ContentDetail,
    MatchDetail
  }
})
export default class Login extends Vue {
  cm = M;
  isDetail = false;
  code = '';
  async tabHanlde(e: {code: string}) {
    this.code = e.code;
    this.isDetail = true;
  }
}
